import { TestSuite } from './../../../models/test-suite.model';
import { CHARTCONFIG } from './../../../charts/charts.config';
import { DashboardService } from './../../../services/dashboard.service';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../../dialogs/handler/handler';
import { Project } from '../../../models/project.model';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { Chart } from 'chart.js';
// import {ShepherdService} from "angular-shepherd";
import { newProjectSteps as defaultSteps, defaultStepOptions } from '../../../shared/data';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { Location } from "@angular/common";
import { OrgService } from '../../../services/org.service';
import downloadCsv from 'download-csv';
import { RunService } from '../../../services/run.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { NotabugCommentsComponent } from "../../../components/dialogs/notabug-comments/notabug-comments.component";
import { RecommendationsCommentsComponent } from "../../../components/dialogs/recommendations-comments/recommendations-comments.component";
import { DeleteDialogComponent } from '../../../components/dialogs/delete-dialog/delete-dialog.component';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { JobsService } from '../../../services/jobs.service';
import { DataTransferService } from '../../../services/data-transfer.service';
import { ELASTICSEARCHVALIDATOR, GlobalProjectDetails, recentProfile, GlobalSecurityModelList, viaProjectList, GlobalSelectedEnv, LoggedInUserOrgId, searchCatResult, selectedActPage, selectPageSize, recentSearchCat, loggedInUserDetail } from "../../../shared/shared.data";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MsgDialogComponent } from '../../dialogs/msg-dialog/msg-dialog.component';
import { AlertDialogComponent } from '../../dialogs/alert-dialog/alert-dialog.component';
import { ResourceService } from '../../../services/resource.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UpgradeComponent } from '../../dialogs/upgrade/upgrade.component';


@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  providers: [ProjectService, DashboardService, OrgService, JobsService, SnackbarService,
    RunService]
})
export class ProjectsListComponent implements OnInit {
  severitiesList = [
    // { 'label': 'All', 'value': 'all' },
    { 'label': 'Critical', 'value': 'Critical' },
    { 'label': 'High', 'value': 'High' },
    { 'label': 'Medium', 'value': 'Medium' },
    { 'label': 'Low', 'value': 'Low' }
  ];
  id = '';
  tests = "-";
  runsChartTile = []; // This will hold our tile chart info
  // config = CHARTCONFIG;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @Output() emitData = new EventEmitter<string[]>();
  projects = [];
  vulSearch: any = null;
  project: Project = new Project();
  projectTitle: string = "Projects";
  showSpinner: boolean = false;
  totalCoverage = 0;
  keyword: string = '';
  vulnerabilityDetails: any;
  projectVulID;
  wireLogDetails: any = [];
  renderedData;
  passed;
  failed;
  viaProjectList = viaProjectList;
  job;
  jobId
  loggedInUserType: any = "";
  loginUser;
  projectFlag: boolean = false;
  currentSuiteName: string;
  sortString2: string = 'severity';
  categoryvul: string = 'all';
  recentProject = 'list';
  projectTour: any;
  dataSource = null;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions = [10, 25, 50, 100];
  ePageSizeOptions = [100, 200, 300];
  searchStringValidator = ELASTICSEARCHVALIDATOR;
  globalSelectedEnv = GlobalSelectedEnv;
  projectRecomData = searchCatResult;
  globalLoggedInUserOrgId = LoggedInUserOrgId;
  globActPageSize = selectedActPage;
  globalProjectDetails = GlobalProjectDetails;
  securityModelScope = GlobalSecurityModelList;
  globalPageSize = selectPageSize;
  showListView: boolean = false;
  showTilesView: boolean = true;
  showVulnerabilityView: boolean = false;
  View = 'list';
  projectType = "All"
  selectedTab = "APIs";
  hideAllGen: boolean = true;
  subscription: any;
  displayedColumns: string[] = ['name', 'endpointsCount', 'playbooksCount', 'bugsOpen', 'baseUrl', 'lastRunCreation', 'tags', 'source', 'owner', 'createdDate'];
  vulColumns: string[] = ['api', 'method', 'owasp', 'category', 'cvss', 'severity', 'action'];
  endPointsColumns: string[] = ['endpointsProjectName', 'endpointsMethod', 'endpointsEndpointCol', 'endpointsDescription', 'summary'];
  displayMode: string;
  vulnerabilityByOrglevel: any;
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  sortType1: string = 'ASC'
  selectedSeverity: any = 'all';
  showEndpointsView: boolean = false;
  tagsList: string[] = ["Prod", "Stg", "Dev", "QA", "Internal", "External", "Apigee", "Postman", "Ecommerce", "Partners"];
  tag: string = "";
  categoryDisplayLabelList: any = [];
  constructor(private projectService: ProjectService,
    private dashboardService: DashboardService,
    private handler: Handler,
    private dialog: MatDialog,
    private runService: RunService,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private jobsService: JobsService,
    private snackbarService: SnackbarService,
    private router: Router, private orgService: OrgService,
    public dataTransfer: DataTransferService,
    private resource: ResourceService,
    private location: Location) {
    this.getNotificationData();
  }
  testsuitesNames: any = [];
  activeCount: any = 0;
  globCatFilter = searchCatResult;
  catSearchBackData = {
    category: '',
    toggleValue: '',
    keyword: '',
    page: 0,
    pageSize: 0,
    activeCount: 0,
    severity: ''
  }

  getNotificationData() {
    this.subscription = this.dataTransfer.getMessage().subscribe(message => {
      // this.length = 0;
      // this.dataTransfer.page = this.dataTransfer.page;
      this.pageSize = 10;
      if (this.recentProject != 'list') {
        this.projectFlag = false
        this.myprojects();
      }
      else {
        this.projectFlag = true;
        this.list();
      }
      this.isChange = message;
    });
  }
  loggedInUserDetail = loggedInUserDetail;
  recentProfile = recentProfile;
  ngOnInit() {

    localStorage.removeItem('regDetails');
    if (this.projectRecomData.catResult) {
      this.projectRecomData['catResult'].keyword = "";
      this.projectRecomData['catResult'].category = "All";
      this.projectRecomData['catResult'].toggleValue = "active";
      this.projectRecomData['catResult'].page = this.dataTransfer.page;
      this.projectRecomData['catResult'].pageSize = 25;
      this.projectRecomData['catResult'].severity = "all";
      this.projectRecomData['catResult'].activeCount = 0;
      this.projectRecomData.catResult = null
    }
    this.globalSelectedEnv.envId = '';
    this.globalPageSize.pageSize_Global = ""
    this.globActPageSize.actPageSize_Global = "";
    this.globalProjectDetails.name = '';
    this.globalProjectDetails.id = '';
    this.securityModelScope.scope = "PUBLIC";
    this.displayMode = localStorage.getItem('displayModeKey');
    this.pageIndex1 = localStorage.getItem('pageindex4');
    this.recentProfile.profileId = 'All';

    if (this.displayMode == 'tile') {
      this.showTilesView = true;
      this.showListView = false;
      this.showVulnerabilityView = false;
      this.showEndpointsView = false;
    }
    if (this.displayMode == 'list' || this.displayMode == null) {
      this.showListView = true;
      this.showTilesView = false;
      this.showVulnerabilityView = false;
      this.showEndpointsView = false;
    }

    this.recentProject = localStorage.getItem('recentProject');
    this.loggedInUserType = localStorage.getItem('loggedInUserType');

    // bind subject for vul table search
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((a) => {
      this.searchbyEndpointVulListPage();
    });

    // get query params to filter vulnerabilities by serverity
    this.route.queryParams.subscribe(qp => {
      if (qp['severity'] != undefined) {
        this.selectedSeverity = qp['severity'];
        this.showVulnerabilityView = true;
        this.showProjects = false;
        this.selectedSeverityList.push(this.selectedSeverity);
        this.router.navigate(['/app/projects/vulnerabilities']);
        this.selectionChangeSeverity()
      }
      if (qp['runId'] != undefined && qp['testSuiteName'] != undefined) {
        this.getTestSuiteResponseByName(qp['runId'], qp['testSuiteName'], null)
      }
      if (this.route.snapshot.url.length == 2 && this.route.snapshot.url[1]['path'] == 'vulnerabilities') {
        this.showVulnerabilityView = true;
        this.showProjects = false;
        this.vulnerabilityView()
      }
      else
        if (this.viaProjectList['route'] == 'vul-list')
          this.router.navigate(['/app/projects/vulnerabilities']);
        else {
          this.showProjects = true;
          this.showVulnerabilityView = false;
          this.showApis();
        }
    })

    // if (this.recentProject == 'myProject') {
    //   this.projectFlag = false
    //   if (this.dataTransfer.keyword) {
    //     this.searchProjects()
    //   } else {
    //     this.myprojects();
    //   }
    //   // this.dataTransfer.keyword = '';
    // }
    // else {
    //   this.projectFlag = true;
    //   if (this.dataTransfer.keyword) {
    //     this.searchProjects();
    //   } else {
    //     this.list();
    //   }this.showApis()
    // }

    //get user role from global variables
    this.loginUser = this.loggedInUserDetail.userRole
    this.orgname = this.loggedInUserDetail.orgName;
    if (!this.loginUser || !this.orgname) {
      // console.log(this.loginUser + Date.now());
      this.loggedinUser();

    }
    this.GetCategoryByOrgId();
    // if (this.viaProjectList['route'] == "Project-list") {
    //    this.showVulnerabilityView = true;
    //   this.showProjects = false;
    //   this.vulnerabilityView();

    // }
    // else {
    //    this.showVulnerabilityView = false;
    //   this.showProjects = true;
    //   this.showApis();
    // }
  }

  setDisplayMode(mode: string) {
    localStorage.setItem('displayModeKey', mode);
    this.displayMode = mode;
    if (this.displayMode === 'tile') {
      this.openTilesView();
      this
    } else if (this.displayMode === 'list') {
      this.openListView();
    }

  }
  /* copyright: APIsec Inc */


  /*
     project-list APIs tab code begins  
 */

  orgname
  loggedinUser() {
    this.handler.activateLoader();
    this.orgService.getLoggedInUser().subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      let loggedInUser = results['data'];
      this.orgId = loggedInUser['org']['id']
      this.orgname = loggedInUser['org']['name'];
      localStorage.removeItem('pageindex4')
      localStorage.setItem('projectName', this.project.name);
      localStorage.setItem('projectId', this.project.id);
      this.globalProjectDetails.name = this.project.name;
      this.loginUser = loggedInUser.orgRole;
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  showApis() {
    this.location.go('/app/projects');
    this.viaProjectList['route'] = "project-list"
    this.displayMode = localStorage.getItem('displayModeKey');
    this.pageIndex1 = localStorage.getItem('pageindex4');
    this.showVulnerabilityView = false;
    this.showProjects = true;
    this.showEndpointsView = false;

    if (this.projects.length == 0) {
      if (this.recentProject == 'myProject') {
        this.projectFlag = false
        if (this.dataTransfer.keyword) {
          this.searchProjects()
        } else {
          this.myprojects();
        }
        // this.dataTransfer.keyword = '';
      }
      else {
        this.projectFlag = true;
        if (this.dataTransfer.keyword) {
          this.searchProjects();
        } else {
          this.list();
        }
      }
    }


  }

  projectToggle() {
    this.dataTransfer.page = 0;
    localStorage.removeItem("pageindex4");
    this.pageSize = 10;
    if (this.projectFlag != true) {
      localStorage.removeItem("recentProject");
      localStorage.setItem("recentProject", "myProject");
      if (!this.dataTransfer.keyword && this.tag != '') {
        this.myprojects();
        localStorage.removeItem("pageindex4");
        this.setSelectedTag('');
      } else {
        this.searchProjects();
      }
    }
    else {
      localStorage.removeItem("recentProject");
      localStorage.setItem("recentProject", "list");
      if (!this.dataTransfer.keyword && this.tag != '') {
        this.list();
        this.setSelectedTag('');
      } else {
        this.searchProjects();
      }
    }
  }

  lastEvent: any;
  //project/API list table sort start here 
  changeSort(obj, event) {
    event.stopPropagation();
    this.sortString = obj;
    // console.log('evernt', event);
    //  if (event.currentTarget.ariaSort == "ascending") this.sortType = "ASC"; else this.sortType = "DESC";
    if (this.sortType == "ASC") this.sortType = "DESC"; else this.sortType = "ASC";
    if (this.lastEvent != null) this.change(this.lastEvent);
    else if (this.dataTransfer.keyword != '')
      this.searchProjects()

    else if (this.projectFlag != true && this.dataTransfer.keyword == '')
      this.myprojects();

    else
      this.list();
  }

  //project/API list table sort ends here 
  list() {
    // this.dataTransfer.keyword = '';
    localStorage.removeItem("recentProject");
    localStorage.setItem("recentProject", "list")
    this.pageIndex1 = localStorage.getItem('pageindex4');
    // this.recentProject = "list";
    // this.projects = [];
    this.handler.activateLoader();
    this.projectService.getProjects(this.dataTransfer.page, this.pageSize, this.sortString, this.sortType, this.tag ?? "").subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      //  results = this.generateData(results);
      this.length = results['totalElements'];
      if (results['data'])
        this.getprojectDetails(results['data']);

      // this.getProjectTour();
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  getprojectDetails(data) {
    this.projects = data;
    this.projects.forEach(x => { if (x.tags) { x.splitTags = x.tags; } else { x.splitTags = [] } });
    // console.log('split tags=', this.projects);
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    // for (let G of this.projects) {
    //   this.totalCoverage = 0;
    //   for (let f of G.activeGenerators) {
    //     if (!(f == 'customize' || f == 'invalid_datatype' || f == 'no_params' || f == 'special_chars ' || f == 'null_value' || f == 'empty_value' || f == 'negative_number')) {
    //       this.totalCoverage = this.totalCoverage + 5;
    //     }
    //   }
    //   G['totalCoverage'] = this.totalCoverage;
    //   if (this.totalCoverage > 100) {
    //     this.totalCoverage = 100;
    //     G['totalCoverage'] = 100;
    //   }
    // }
  }

  length = 0;
  // page = 0;
  isChange: any = "";
  pageIndex1: any = 0;
  //project list page pagination starts here
  change(evt) {

    this.dataTransfer.page = evt['pageIndex'];
    localStorage.setItem('pageindex4', this.dataTransfer.page);
    this.pageSize = evt['pageSize'];
    if (this.dataTransfer.keyword != '' || this.tag) {
      this.searchProjects()
    }
    else
      if (this.projectFlag != true && this.dataTransfer.keyword == '') {
        this.myprojects();
      }
      else {
        this.list();
      }
  }
  //project list page pagination starts here



  //vulnerability table pagination ends here

  searchProjects(resetPage: boolean = false) {

    // for project manage cannot search other project he 
    if (this.loginUser == 'PROJECT_MANAGER') {
      this.projectFlag = false;
    }
    if ((this.dataTransfer.keyword == "" && this.tag == "") || resetPage) {
      this.dataTransfer.page = 0;
      this.pageSize = 10;
      localStorage.removeItem("pageindex4");
      this.pageIndex1 = 0;
    }
    this.pageIndex1 = localStorage.getItem('pageindex4') ?? 0;
    this.handler.activateLoader();
    this.projectService.getProjectSearch(this.dataTransfer.page, this.pageSize, this.dataTransfer.keyword, this.projectFlag, this.sortString, this.sortType, this.tag ?? "").subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      //    results = this.generateData(results);
      this.projects = results['data'];
      //    console.log("hello..");
      //  if (this.projects && this.projects.length > 0)
      this.getprojectDetails(this.projects);
      this.length = results['totalElements'];
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  setSelectedTag(tag: string) {
    this.tag = tag ?? '';
    this.searchProjects(true);
  }

  validateSearchProject() {
    this.dataTransfer.page = 0;
    localStorage.removeItem("pageindex4");
    this.searchProjects()
  }

  getvulDetails(data) {
    this.vulSearch = data;
    this.vuldatasource = new MatTableDataSource(data);
    this.vuldatasource.sort = this.sort;
  }
  /*Search by Endpoint for vulnerability code begins  */

  subjectKeyUp = new Subject<any>();
  resultOfVul: boolean = true;
  searchVulTable($event) {
    const key = $event.keyCode;
    if (this.searchStringValidator.isValidSearchKeyCode(key)) {
      this.keyword = $event.target.value;
      this.subjectKeyUp.next(this.keyword);
    }
  }

  searchbyEndpointVulListPage() {
    // if (this.keyword.length == 0 || this.keyword.length >= 3) {
    this.resultOfVul = false;
    // const key = event.keyCode;
    // if (this.searchStringValidator.isValidSearchKeyCode(key)) {
    this.keyword = this.keyword.trim();
    this.vulnerabilityPage = 0;
    if (!this.selectedSeverity) this.selectedSeverity = 'all';
    this.handler.activateLoader();
    this.projectService.getOrgVulnerabilities(this.vulnerabilityPage, this.vulnerabilityPageSize, this.sortString2, this.sortType1, this.selectedSeverity, this.selectedCategories, this.keyword).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.vulSearch = results['data'];
      this.getvulDetails(this.vulSearch)
      this.vulnerabilityLength = results['totalElements'];

      this.resultOfVul = true;
    }, error => {
      this.resultOfVul = true;
      this.handler.hideLoader();
      this.handler.error(error);
    });
    // }
    // }
  }

  /* Search by Endpoint for vulnerability code ends  */
  myprojects() {
    //this.dataTransfer.keyword = '';
    localStorage.removeItem("recentProject");
    localStorage.setItem("recentProject", "myProject");
    this.pageIndex1 = localStorage.getItem('pageindex4');
    // this.recentProject = "myProject";
    // this.projects = [];
    this.handler.activateLoader();
    this.projectService.getMyProjects(this.dataTransfer.page, this.pageSize, this.sortString, this.sortType, this.tag ?? "").subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      //  results = this.generateData(results);
      this.length = results['totalElements'];
      if (results['data'])
        this.getprojectDetails(results['data']);

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });

  }

  openListView() {
    // this.dataTransfer.keyword = '';
    localStorage.removeItem("displayModeKey");
    localStorage.setItem("displayModeKey", "list");
    this.hideAllGen = true;
    this.showListView = true;
    this.showTilesView = false;
    this.View = 'List';
  }

  openTilesView() {
    // this.dataTransfer.keyword = '';
    localStorage.removeItem("displayModeKey");
    localStorage.setItem("displayModeKey", "tile");
    this.View = 'Tiles';
    this.showListView = false;
    this.showTilesView = true;
  }

  navigateTo(row: any) {
    this.router.navigate(['/app/projects/' + row.id + '/dashboard']);
  }

  // On input focus: setup filterPredicate to only filter by input column
  setupFilter(column: string) {
    this.dataSource.filterPredicate = (data, filter) => {
      const textToSearch = data[column] && data[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    localStorage.removeItem('suitename');
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }


  /*

    *Copyright: APIsec Inc.  

  */
  //-----------------------------------------------project-list APIs tab code ends --------------------------------------- 


  /*

  Vulnerabilities list Code begin
  
 */

  vulnerabilityPage = 0;
  vulnerabilityPageSize = 25;
  orgId: any;
  vuldatasource;
  vulnerabilityLength = 0;
  showProjects: boolean = true;
  vulData;
  testsuitename2
  vulid
  vulneKeyword;
  //Vulnerability tab  code starts here 
  vulnerabilityView() {
    if (this.selectedSeverityList.length == 0) {
      this.selectedSeverity = 'all'
    }
    this.viaProjectList['route'] = 'vul-list';
    this.handler.activateLoader();
    this.projectService.getOrgVulnerabilities(this.vulnerabilityPage, this.vulnerabilityPageSize, this.sortString2, this.sortType1, this.selectedSeverity, this.selectedCategories, this.keyword).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.vulnerabilityLength = results['totalElements'];
      var data = results['data'];
      this.vuldatasource = new MatTableDataSource(data);
      this.vuldatasource.sort = this.sort;
      this.vulData = results['data'];

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  changeSortVul(obj, event) {
    event.stopPropagation();
    this.sortString2 = obj;
    if (this.sortType1 == "DESC") this.sortType1 = "ASC"; else this.sortType1 = "DESC";
    if (this.lastEvent != null) this.change(this.lastEvent);
    this.vulnerabilityView();
  }

  //vulnerability table pagination starts here
  changeVul(evt) {
    // this.lastEvent = evt;
    this.vulnerabilityPageSize = evt.pageSize;
    this.vulnerabilityPage = evt['pageIndex'];
    this.vulnerabilityView();
  }

  showVulnerabilities() {
    // this.viaProjectList['route'] = "Project-list";
    this.showVulnerabilityView = true;
    this.viaProjectList['route'] = 'vul-list';
    // this.router.navigate(['/app/projects/vulnerabilities']);
    this.location.go('/app/projects/vulnerabilities')
    this.showProjects = false;
    this.showEndpointsView = false;
    if (this.vulnerabilityLength == 0) {
      this.vulnerabilityView();
    }
  }

  //Vulnerability tab table code ends here 
  synAll: any = null;
  masterJob;
  jobId1;
  // show logs
  getTestSuiteResponseByName(runId, name: string, generatorType: string) {
    this.handler.activateLoader();
    if (generatorType && generatorType == 'task') {
      this.handler.hideLoader();
      this.showLogsDialog("Scan 'RBAC' category, Review RBAC Map & commit ", '', '');
    }
    else {
      this.runService.getTestSuiteResponseByName(runId, name).subscribe(
        results => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          let i = 0;
          this.list = results['data'];
          this.testsuit1 = this.list[i]['testSuite']
          // console.log('testsuitenames1', this.testsuit1);
          this.id = this.list[i]['id']
          this.jobId1 = this.list[i]['runId']
          // localStorage.setItem('testsuitesNames', this.testsuitesNames);
          let msg = '';
          for (let i = 0; i < this.list.length; i++) {
            msg += this.list[i].logs;
          }
          if (this.list && this.list.length > 0) {
            this.passed = this.list[0].totalPassed;
            this.failed = this.list[0].totalFailed;
          }
          this.showLogsDialog(msg, runId, name);
          localStorage.setItem('testsuitesNames', this.testsuitesNames);
        },
        error => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    }
  }

  testsuit1: any = [];
  showLogsDialog(msg, runId, suiteNames) {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(MsgDialogComponent, {
        width: '1400px',
        maxWidth: '90vw',
        data: [
          msg,
          suiteNames,
          this.testsuit1,
          this.id,
          this.jobId,
          runId,
          this.passed,
          this.failed,
          'hide'
        ]

      });

    }
  }
  //view logs pop code ends here
  editorSelect: any = { rowText: '', validEvent: false, text: '', exec: false, row: 0, column: 0, prevExecuted: false };
  testsuite: any = null;
  keywordpb = '';

  //Download all projects code
  autoSuggestForAllDownload = [];
  totalElementsExcel = 0;

  categoriesCount = [];
  ExcelData = [];
  excel = [];
  autoSuggestForDownload: any = null;
  arrSelectedValues = [];
  headers = {};
  globPageSize = selectPageSize;
  storedatapage
  globRecentCat = recentSearchCat;
  // disableDownloadVul: boolean = false;
  // Download org vulnerbilities code start from here

  downloadAllProjectVul() {
    this.page = 0;
    this.autoSuggestForAllDownload = [];
    // this.disableDownloadVul = true;
    this.snackbarService.open(
      "It will take few minutes to download all vulnerabilities.",
      "OK",
      30000
    );

    this.ExportTOAllExcel();
  }

  page = 0;
  ExportTOAllExcel() {
    this.handler.activateLoader();
    this.projectService
      //.getAutoSuggestionsForAllDownload(this.orgId, this.page, 100, this.sortString2, this.sortType1, this.categoryvul, this.selectedSeverity)
      .getOrgVulnerabilities(this.page, 1000, this.sortString2, this.sortType1, this.selectedSeverity, '', '')
      .subscribe(results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        var tmpAutoSuggestForAllDownload = results['data'];
        this.totalElementsExcel = results['totalElements'];

        this.autoSuggestForAllDownload = this.autoSuggestForAllDownload.concat(tmpAutoSuggestForAllDownload);

        if (this.autoSuggestForAllDownload.length < this.totalElementsExcel) {
          this.page = this.page + 1;
          this.ExportTOAllExcel()
        }
        if (this.autoSuggestForAllDownload.length == this.totalElementsExcel) {
          // console.log(this.autoSuggestForAllDownload.length)
          this.loadExcelDataForAllProject(this.autoSuggestForAllDownload, this.orgname)
        }

        // this.loadExcelData(this.project.org.name);
      },

        error => {
          // this.disableDownloadVul = false;
          this.handler.hideLoader();
          this.handler.error(error);
        });
  }


  toggleValue = 'active';
  envList = [];
  selectedEnv = '';
  selectedEnvObj;
  category: string = 'All';

  loadExcelDataForAllProject(tmpAutoSuggestForDownload, fileName) {
    // this.disableDownloadVul = false;
    //  this.loadExcelData();
    this.ExcelData = [];
    this.arrSelectedValues = [];
    this.headers = {};
    this.headers['API'] = '';
    this.headers['OWASP Rank'] = '';
    this.headers['Method'] = '';
    this.headers['Endpoint'] = '';
    this.headers['Resource Name'] = '';
    this.headers['Category'] = '';
    this.headers['CVSS 3.1'] = '';
    this.headers['Severity'] = '';
    this.headers['Since'] = '';
    this.headers['Auth'] = '';
    this.headers['Status'] = '';
    this.headers['Status Code'] = '';
    this.headers['Playbook Name'] = '';
    this.headers['Bug Bounty Savings'] = '';
    this.headers['Vulnerability link'] = '';
    this.headers['Wirelog Link'] = '';
    this.ExcelData = tmpAutoSuggestForDownload;
    var host = window.location.origin
    if (this.ExcelData && this.ExcelData.length > 0) {
      for (let i = 0; i < this.ExcelData.length; i++) {
        let vulTime = '';
        vulTime = moment(this.ExcelData[i].createdDate).fromNow();
        if (this.ExcelData[i].createdDate) {
          this.ExcelData[i]['created_date'] = '';
          this.ExcelData[i]['created_date'] = vulTime;
        }
        this.ExcelData[i]['API'] = this.ExcelData[i]['name'];
        this.ExcelData[i]['Resource Name'] = this.ExcelData[i]['resourceName'];
        this.ExcelData[i]['Method'] = this.ExcelData[i]['method'];
        this.ExcelData[i]['Endpoint'] = this.ExcelData[i]['endPoint'];
        this.ExcelData[i]['Category'] = this.ExcelData[i]['categoryDisplayLabel'];
        this.ExcelData[i]['Since'] = this.ExcelData[i]['created_date'];
        this.ExcelData[i]['Auth'] = this.ExcelData[i]['auth'];
        this.ExcelData[i]['Bug Bounty Savings'] = '$' + this.ExcelData[i]['bounty'];
        this.ExcelData[i]['OWASP Rank'] = this.ExcelData[i]['rank'];
        this.ExcelData[i]['CVSS 3.1'] = this.ExcelData[i]['cvssScore'];
        if (tmpAutoSuggestForDownload[i]['status'] == 'NEW') {
          this.ExcelData[i]['Status'] = 'Open';
        } else if (
          this.ExcelData[i]['status'] == 'CLOSED' ||
          this.ExcelData[i]['status'] == 'SKIPPED'
        ) {
          this.ExcelData[i]['Status'] = 'Closed';
        } else {
          this.ExcelData[i]['Status'] = 'Closed';
        }
        this.ExcelData[i]['Severity'] = this.ExcelData[i]['severity'];
        this.ExcelData[i]['Status Code'] = this.ExcelData[i]['respStatusCode'];
        this.ExcelData[i]['Playbook Name'] = this.ExcelData[i]['testSuiteName'];
        this.ExcelData[i]['Vulnerability link'] = host + "/#/app/vulnerabilities/projects/" + this.ExcelData[i]['projectId'] + "/dashboard/" + this.ExcelData[i]['id'] + "/details";
        if (this.ExcelData[i]['generatorType'] != 'task' && this.ExcelData[i]['generatorType'] != 'Compliance1' && this.ExcelData[i]['generatorType'] != 'Compliance2')
          this.ExcelData[i]['Wirelog Link'] = host + "/#/app/projects/vulnerabilities?runId=" + this.ExcelData[i]['runId'] + "&testSuiteName=" + this.ExcelData[i]['testSuiteName'];
        else this.ExcelData[i]['Wirelog Link'] = "";
        const element = this.ExcelData[i];
        const obj = JSON.parse(JSON.stringify(this.headers));
        Object.keys(obj).forEach(function (key) {
          Object.keys(element).forEach(function (key2) {
            if (key.toLowerCase() == key2.toLowerCase()) {
              obj[key] = element[key2];
            }
          });
        });


        this.arrSelectedValues.push(obj);
        //Sort by Category, and then Severity
      }

      this.arrSelectedValues.sort(function (a: any, b: any) {
        if (a['Severity'])
          var aa = a['Severity'].toString().split(/(\d+)/);
        if (b['Severity'])
          var bb = b['Severity'].toString().split(/(\d+)/);
        if (a['Category'])
          var aaname = a['Category'].toString().split(/(\d+)/);
        if (b['Category'])
          var bbname = b['Category'].toString().split(/(\d+)/);

        for (let x = 0; x < Math.max(aa.length, bb.length); x++) {
          if (aa[x] != bb[x]) {
            const cmp1 = (isNaN(parseInt(aa[x], 10))) ? aa[x] : parseInt(aa[x], 10);
            const cmp2 = (isNaN(parseInt(bb[x], 10))) ? bb[x] : parseInt(bb[x], 10);
            if (cmp1 == undefined || cmp2 == undefined)
              return aa.length - bb.length;
            else
              return (cmp1 < cmp2) ? -1 : 1;
          }
        }

        for (let x = 0; x < Math.max(aaname.length, bbname.length); x++) {
          if (aaname[x] != bbname[x]) {
            const cmp1name = (isNaN(parseInt(aaname[x], 10))) ? aaname[x] : parseInt(aaname[x], 10);
            const cmp2name = (isNaN(parseInt(bbname[x], 10))) ? bbname[x] : parseInt(bbname[x], 10);
            if (cmp1name == undefined || cmp2name == undefined)
              return aaname.length - bbname.length;
            else
              return (cmp1name < cmp2name) ? -1 : 1;
          }
        }
        return 0;
      });

      let objectMaxLength = [];
      for (let i = 0; i < this.arrSelectedValues.length; i++) {
        let value = <any>Object.values(this.arrSelectedValues[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 14;
          } else {
            if (value[j] && value[j].length) {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? objectMaxLength[j]
                  : value[j].length;
            }
          }
        }
      }

      var wscols = [
        { width: objectMaxLength[0] },
        { width: objectMaxLength[1] = '12' },  // first column
        { width: objectMaxLength[2] = '10' },  // second column
        { width: objectMaxLength[3] = '25' },
        { width: objectMaxLength[4] = '18' },
        { width: objectMaxLength[5] = '17' },
        { width: objectMaxLength[6] = '10' },
        { width: objectMaxLength[7] = '10' },
        { width: objectMaxLength[8] = '15' },
        { width: objectMaxLength[9] = '15' },
        { width: objectMaxLength[10] = '10' },
        { width: objectMaxLength[11] = '12' },
        { width: objectMaxLength[12] = '22' },
        { width: objectMaxLength[13] = '17' },
        { width: objectMaxLength[14] = '25' },
        { width: objectMaxLength[15] }
      ];
      // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrSelectedValues);
      // worksheet["!cols"] = wscols;
      // const workBook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      // if (this.orgname) {
      //   let xlFileName = fileName + '_Vulnerabilities.xlsx';
      //   XLSX.writeFile(workBook, xlFileName, { bookType: 'xlsx', type: 'buffer' }); // initiate a file download in browser
      // }

      downloadCsv(this.arrSelectedValues, '', this.orgname + '_Vulnerabilities.csv');
    }
    else {
      // alert('No Vulnerability Available');
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        width: "580px",
        height: "210px",
        data: ["", "No Vulnerability Available"]
      });

    }

  }
  //Download org vulnerbilities code ends here

  storedData() {
    if (this.globPageSize.pageSize_Global) {
      this.storedatapage = this.globPageSize.pageSize_Global['pageIndex'];
      this.pageSize = this.globPageSize.pageSize_Global['pageSize'];
    }
    else {
      this.storedatapage = 0;
      this.pageIndex = 0;
    }
    this.globRecentCat.recent_global = '';
    this.globRecentCat.recentSrc_global = '';
    if (this.selectedSeverity == undefined) {
      this.selectedSeverity = 'all'
    }
  }

  goToDashboard(project) {
    // if (this.displayMode == 'tile')
    //   this.viaProjectList['route'] = '!Project-list';
    this.router.navigate(['/app/projects/', project.id, 'dashboard']);
  }

  showVul(e) {
    this.viaProjectList['route'] = 'vul-list'
    const tempData: any = JSON.stringify(this.vulData);
    localStorage.setItem('vulname', tempData);
    this.vulnerabilityDetails = e;
    // this.projectVulID = this.vulData[0]['projectId']
    this.catSearchBackData = null;
    if (this.category == 'All') {
      this.category = '';
    }
    this.catSearchBackData = {
      category: this.category,
      toggleValue: this.toggleValue,
      keyword: this.keyword,
      page: this.page,
      pageSize: this.pageSize,
      activeCount: this.activeCount,
      severity: this.selectedSeverity
    }
    this.globCatFilter.catResult = this.catSearchBackData;
    this.router.navigate(['/app/vulnerabilities/projects/', e.projectId, 'dashboard', e.id, 'details']);
  }

  // Dismiss vulnerability code starts from here 
  deactivateAutoSuggestion(obj) {

    if (obj.category != "RBAC") {
      obj.rbacAction = "";
    }
    // invoke skip service
    this.projectService
      .skipAutoSuggestion(
        obj.id,
        obj.testSuiteName,
        obj.testCaseNumber,
        obj.rbacAction
      )

      .subscribe(results => {
        if (this.handler.handle(results)) {

          return;
        }
        this.vulnerabilityView()
        // this.router.navigate(['/app/projects']);
      });
  }

  openNotaBugCommentsDialog(vulnerabilityDetails) {
    vulnerabilityDetails.comment = '';
    let dialogRef = this.dialog.open(NotabugCommentsComponent, {
      width: "800px",
      data: [vulnerabilityDetails, vulnerabilityDetails.projectId, this.toggleValue]
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result[0]) {
          this.deactivateAutoSuggestion(vulnerabilityDetails);
        }
      }

    });

  }
  //Dismiss vulnerability code ends here 


  // activateAutoSuggestion(obj) {
  //   this.projectService
  //     .activateAutoSuggestion(obj.id, obj.testSuiteName, obj.testCaseNumber)
  //     .subscribe(results => {
  //       if (this.handler.handle(results)) {
  //         return;
  //       }
  //       this.vulnerabilityView()
  //     });
  // }

  archiveVul(vulDetails) {
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "650px",
      height: "275px",
      data: ["Dismiss", "This action will reduce the bug bounty estimates and delete the open vulnerability in APIsec and close in the registered issue-tracker. Are you sure you want to delete this vulnerability?"]
    });
    dialogRef.componentInstance.emitData.subscribe(data1 => {
      if (data1 == 'Y') {
        this.snackbarService.openSnackBar("Dismissing vulnerability...", "");
        this.projectService.archiveSuggestion(vulDetails.projectId, vulDetails.id).subscribe(
          results => {

            this.snackbarService.openSnackBar(
              "Successfully dismissed successfully",
              ""
            );
            this.vulnerabilityView()
          },
          error => {
            this.handler.hideLoader();
            this.handler.error(error);
          }
        );
      }
    })
  }


  PopulateCoverage(objList) {
    let tObj = { resourceName: '', endpoints: [] }
    let cont = 1;
    let lastName = ''
    try {
      lastName = objList[0][4];
    } catch (e) { }
    tObj.resourceName = lastName;
    let resourceDefinitionList = [];
    objList.forEach(function (item) {
      let obj = { "projectName": item[6], "method": item[1], "endpoint": item[0], "description": item[3], "summary": item[4], "isManual": item[5] };
      if (lastName == item[4]) tObj.endpoints.push(obj);
      else if (lastName != item[4]) {
        resourceDefinitionList.push(tObj);
        tObj = { resourceName: item[4], endpoints: [obj] }; lastName = item[4]
      }
    });
    var arr: any = []
    arr = tObj;
    resourceDefinitionList.push(arr)
    return resourceDefinitionList;
  }
  //Download all Endpoint code ends here

  //Download all APIs code starts from here

  downloadAllProjectsEndpoints() {
    this.snackbarService.openSnackBar('Downloading endpoints... ', '');
    this.resource.getAllProjectsEndpoints().subscribe((results) => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.allProjectsEndpointsList = this.PopulateCoverage(results['data']);
      this.exportAsXLSX();
      this.snackBar.dismiss();
    }, (error) => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  xlData = [];
  totalPages = 1;
  totalApis;
  page3 = 0;
  downloadAllAPIs() {
    this.page3 = 0;
    this.xlData = [];
    this.snackbarService.openSnackBar('Downloading all APIs data... ', "");
    this.exportToAllAPI();
  }

  exportToAllAPI() {
    // var v = 0;
    this.handler.activateLoader();

    this.projectService.getProjects(this.page3, 100, this.sortString, this.sortType, this.tag ?? "").subscribe(results => {
      // this.xlData = [];

      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }

      this.xlData = this.xlData.concat(results['data']);
      // this.totalApis = results['totalElements'];
      if (this.xlData.length < results['totalElements']) {
        this.page3 = this.page3 + 1;
        this.exportToAllAPI()
      }
      if (this.xlData.length == results['totalElements']) {
        this.writeToXlforAPI();
      }
      // this.xlData.length = 0;

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
    // this.fetchProjectsData(i)
  }

  //Get list of cateogires name by orgId.
  GetCategoryByOrgId() {
    this.handler.activateLoader();
    this.projectService.getCateogoryByorgId().subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.categoryDisplayLabelList = results['data'];
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  writeToXlforAPI() {
    this.ExcelData = [];
    this.arrSelectedValues = [];
    this.headers = {};
    this.headers['API Name'] = '';
    this.headers['Owner'] = '';
    this.headers['Endpoints'] = '';
    this.headers['Playbooks'] = '';
    this.headers['Vulnerabilities'] = '';
    this.headers['BaseUrl'] = '';
    this.headers['Created date'] = '';
    this.headers['Env Reachable'] = '';
    this.headers['Scanner Name'] = '';
    this.headers['Env Reachable On'] = '';
    //sorting
    this.xlData.sort((a, b) => (a.bugsOpen > b.bugsOpen ? -1 : 1));

    this.ExcelData = this.xlData;
    for (var i = 0; i < this.xlData.length; i++) {
      let APITime = '';
      APITime = moment(this.xlData[i].createdDate).format('MMM DD YYYY');
      if (this.xlData[i].createdDate) {
        this.xlData[i]['created_date'] = '';
        this.xlData[i]['created_date'] = APITime;
      }
      this.ExcelData[i]['API Name'] = this.xlData[i]['name']
      this.ExcelData[i]['Owner'] = this.xlData[i]['owner']
      this.ExcelData[i]['Endpoints'] = this.xlData[i]['endpointsCount']
      this.ExcelData[i]['Playbooks'] = this.xlData[i]['playbooksCount']
      this.ExcelData[i]['Vulnerabilities'] = this.xlData[i]['bugsOpen']
      this.ExcelData[i]['BaseUrl'] = this.xlData[i]['baseUrl']
      this.ExcelData[i]['Created date'] = this.xlData[i]['created_date']
      this.ExcelData[i]['Env Reachable'] = this.xlData[i]['envReachable'] ? 'Y' : 'N'
      this.ExcelData[i]['Scanner Name'] = this.xlData[i]['region'] ? this.xlData[i]['region'] : 'NA'
      this.ExcelData[i]['Env Reachable On'] = this.xlData[i]['urlReachableOn'] ? moment(this.xlData[i]['urlReachableOn']).format('MMM DD YYYY') : 'NA'

      let vulTime = '';
      vulTime = moment(this.ExcelData[i]['lastRunCreation']).format('ll');
      if (this.ExcelData[i]['lastRunCreation']) {
        this.ExcelData[i]['last_scan_date'] = '';
        this.ExcelData[i]['Last Scan Date'] = vulTime;
      }
      else
        this.ExcelData[i]['Last Scan Date'] = 'N/A'

      const element = this.ExcelData[i];
      const obj = JSON.parse(JSON.stringify(this.headers));
      Object.keys(obj).forEach(function (key) {
        Object.keys(element).forEach(function (key2) {
          if (key.toLowerCase() == key2.toLowerCase()) {
            obj[key] = element[key2];
          }
        });
      });
      this.arrSelectedValues.push(obj);
      // this.arrSelectedValues.push(this.);
    }
    // this.dataSource1 = new MatTableDataSource(this.xlData);
    // this.dataSource1.sort = this.sort;

    this.snackbarService.snackBar.dismiss();
    downloadCsv(this.arrSelectedValues, '', this.orgname + '_APIs.csv');

    // let xlFileName = this.orgname + " APIs.xlsx";

    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrSelectedValues);
    // const workBook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };
    // XLSX.writeFile(workBook, xlFileName, { bookType: 'xlsx', type: 'buffer' }); // initiate a file download in browser

  }

  // removeSeverityFilter() {
  //   this.selectedSeverity = 'all';
  //   this.router.navigate(['/app/projects/vulnerabilities']);
  //   // this.vulnerabilityView();

  // }
  /*
 
  copyright: APIsec Inc.
 
*/

  // ------------------------------------------------- Vulnerabilities list code ends -------------------------------------


  /*
  
  Download Endpoint and endpoints table code begins... 
  
  */


  changeEndPoints(evt) {
    // this.lastEvent = evt;
    this.ePageSize = evt.pageSize;
    // this.endPointsPageSize = evt.pageSize;
    this.ePage = evt['pageIndex'];
    this.showAllProjectsEndpoints();
  }
  //Endpoints View
  endpointsDataSource;
  eLength = 0;
  ePage = 0;
  ePageSize = 100;
  endpointsLength;
  allProjectsEndpointsList = [];

  showAllProjectsEndpoints() {
    this.resource.getAllProjectsEndpoints().subscribe((results) => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      var data = results['data'];
      this.endpointsDataSource = new MatTableDataSource(data);
      this.endpointsDataSource.paginator = this.paginator;
      this.endpointsLength = data.length;
      // console.log(this.endpointsLength)
      // this.exporter.exportTable('xlsx', {fileName: orgname + '_Endpoints.xlsx'})

    }, (error) => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  resourceDefinitionList;
  arrSelectedValuesEndpoint = [];
  obj = { "Method": "", "Endpoint": "", "Description": "", "Summary": "" };
  objAllEndpoints = { "API Name": "", "Method": "", "Endpoint": "", "Description": "", "Summary": "" };
  //Download all Endpoint code starts from here 
  exportAsXLSX() {
    var projectName = ""; var summary = ""; var endpoint = ""; var method = ""; var description = ""; var isManual = "";
    this.arrSelectedValuesEndpoint = [];
    var tempEndpointsList = [];
    //  const columnsEndpoint = ['API Name', 'Method', 'Endpoint', 'Description', 'Summary']
    const endpointsData = []
    tempEndpointsList = this.allProjectsEndpointsList;

    for (var i = 0; i < tempEndpointsList.length; i++) {
      if (tempEndpointsList[i]) {
        for (var j = 0; j < tempEndpointsList[i].endpoints.length; j++) {
          if (tempEndpointsList[i].endpoints)
            if (tempEndpointsList[i].endpoints[j].summary != null) {
              summary = tempEndpointsList[i].endpoints[j].summary;
            }
            else
              summary = "-";
          if (tempEndpointsList[i].endpoints[j].description != null) {
            description = tempEndpointsList[i].endpoints[j].description;
          }
          else
            description = "-";
          endpoint = tempEndpointsList[i].endpoints[j].endpoint;
          method = tempEndpointsList[i].endpoints[j].method;
          projectName = tempEndpointsList[i].endpoints[j].projectName;
          this.objAllEndpoints = { "API Name": projectName, "Method": method, "Endpoint": endpoint, "Description": description, "Summary": summary }
          // this.arrSelectedValuesEndpoint.push(this.objAllEndpoints);

          endpointsData.push(this.objAllEndpoints)
        }
      }

    }

    downloadCsv(endpointsData, '', this.orgname + '_Endpoints.csv');
    // let objectMaxLength = [];
    // for (let i = 0; i < this.arrSelectedValuesEndpoint.length; i++) {
    //     let value = <any>Object.values(this.arrSelectedValuesEndpoint[i]);
    //     for (let j = 0; j < value.length; j++) {
    //         if (typeof value[j] == "number") {
    //             objectMaxLength[j] = 14;
    //         } else {
    //             if (value[j] && value[j].length) {
    //                 objectMaxLength[j] =
    //                     objectMaxLength[j] >= value[j].length
    //                         ? objectMaxLength[j]
    //                         : value[j].length;
    //             }
    //         }
    //     }
    // }

    // let wscols = [
    //     { width: objectMaxLength[0] = '15' },  // first column
    //     { width: objectMaxLength[1] },// second column
    //     { width: objectMaxLength[2] },
    //     { width: objectMaxLength[3] },
    //     { width: objectMaxLength[4] },
    // ];

    // const workbook = XLSX.utils.book_new(); // create a new blank book
    // const worksheet = XLSX.utils.json_to_sheet(this.arrSelectedValuesEndpoint);
    // worksheet["!cols"] = wscols;
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Endpoints'); // add the worksheet to the book

    // if (allEndpoints)
    //     XLSX.writeFile(workbook, this.orgname + '_Endpoints.xlsx'); // initiate a file download in browser
    // else
    //     XLSX.writeFile(workbook, this.orgname + '_Endpoints.xlsx'); // initiate a file download in browser
  }

  showEndpoints() {
    this.showEndpointsView = true;
    this.showProjects = false;
    this.showVulnerabilityView = false;
  }

  //-----------------------------------------------------Endpoints Tab Code Ends ------------------------------------

  /* 
  Developers are requested to add comments for further code 
    */
  loggedInUserRole:any="";
  getNewPage(value) {
    this.loggedInUserRole = localStorage.getItem('orgRole');
    if(!this.loggedInUserRole)  this.loggedInUserRole = localStorage.getItem('userRole'); 
    if (this.loggedInUserRole == 'BASIC' && value == 'Integrations') {
      this.upgradeSubscription()
    }
    else{
    this.keyword = "";
    this.dataTransfer.keyword = "";
    if (value == 'Register') this.router.navigate(['/app/projects/new']);
    if (value == 'Apigateway') this.router.navigate(['/app/apigateway/list']);
    if (value == 'Scanners') this.router.navigate(['/app/scanners/list']);
    if (value == 'Integrations') this.router.navigate(['/app/integrations/report-storage']);
    if (value == 'securityCategories') this.router.navigate(['/app/securityCategories/list']);
    if (value == 'dashboard') this.router.navigate(['/app/security-center-dashboard/list']);
  }
}

upgradeSubscription(): void {
  const dialogRef = this.dialog.open(UpgradeComponent, {
    width: '450px',
    disableClose: true
  });
}
  //Selected Severity
  // getSelectedSeverity() {
  //   this.router.navigate(['/app/projects/vulnerabilities']);
  //   this.showVulnerabilityView = true;
  //   this.showProjects = false;
  //   this.vulnerabilityView();
  // }

  selectedSeverityList: any = [];
  selectedCategories: any = [];
  selectionChangeSeverity() {
    this.showVulnerabilityView = true;
    this.showProjects = false;
    if (this.selectedSeverityList.length == this.severitiesList.length) {
      this.allComplete = true;
      this.intermediateFlag = false;
      this.selectedSeverity = this.selectedSeverityList;
      this.vulnerabilityView();
    }
    else if (this.selectedSeverityList.length < this.severitiesList.length && this.selectedSeverityList.length!=0) {
      this.intermediateFlag = true;
      this.allComplete = false;
      this.selectedSeverity = this.selectedSeverityList;
      this.vulnerabilityView();
    }
    else {
      this.allComplete = false;
      this.intermediateFlag = false;
      this.selectedSeverity = this.selectedSeverityList;
      this.vulnerabilityView();
    }
    if (this.selectedSeverityList.length == 0) {
      this.selectedSeverity = 'all'
    }
  }

  selectionChangeCategory() {
    // if (this.selectedCategories.length < this.categoryDisplayLabelList.length) {
    //   this.intermediateFlag = true;
    //   this.allComplete = false;

    this.vulnerabilityView();
    // }

  }

  allComplete: boolean = false;
  intermediateFlag: boolean = false;
  setAll(e) {
    if (e) {
      this.selectedSeverityList = this.severitiesList.map(item => {
        return item.value;
      });
      this.selectedSeverity = 'all';
      this.vulnerabilityView();
      this.allComplete = true;
    }
    else {
      this.allComplete = false;
      this.selectedSeverityList = [];
      this.selectedSeverity = 'all';
      this.vulnerabilityView();
    }

  }

  // selectionChangeCreate() {
  //   if (this.selectedSeverityList.length == this.severitiesList.length) {
  //     this.allComplete = true;
  //     this.intermediateFlag = false;
  //   }

  //   else if (this.selectedSeverityList.length != 0 && (this.selectedSeverityList.length < this.selectedSeverityList.length)) {
  //     this.intermediateFlag = true;
  //     this.allComplete = false;
  //   }
  //   else {
  //     this.allComplete = false;
  //     this.intermediateFlag = false;
  //   }
  // }

}
