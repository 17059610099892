import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Project, APIProjects } from "../models/project.model";
import { ProjectSync } from "../models/project-sync.model";
import { AutoCodeConfig } from "../models/project-autocode-config.model";
import { Env } from "../models/project-env.model";
import { ProjectConf, Personalized } from "../models/project-conf.model";
import { identifierModuleUrl } from "@angular/compiler";
import { ReportSetting } from "../models/report-setting";

@Injectable()
export class ProjectService {
  private serviceUrl = "/api/v1/projects";
  private projectConf = "/api/v1/generator-configuration";
  private envServiceUrl = "/api/v1/envs";
  private autocodeURL = "./assets/auto-code.json";
  private reportURL = "api/v1/reports";
  private reportSettingURL = "api/v1/report-setting";
  private projectSearch = "api/v1/projects/search";
  // private personalizedURL = "api/v1/personalized-coverage";
  private gatewayURL = "/api/v1/api-gateway"

  private postmanToOpenApi = "http://application.apisec.ai:18000/postmantooas";
  private ramlToOpenApi = "http://application.apisec.ai:3000/convertfromstring";
  constructor(private http: HttpClient) { }

  convertPostmanToOpenApi(fileData) {
    return this.http.post(this.postmanToOpenApi, fileData);
  }
  convertRamlToOpenApi(fileData) {
    return this.http.post(this.ramlToOpenApi, { data: fileData });
  }
  // /api/v1/projects/search?page=0&pageSize=20

  /**
   * Get the jobs in observable from endpoint
   */
  getAllProjects(page, pageSize, status, orgIds) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("orgId", orgIds);
    return this.http.get(this.serviceUrl + "/mssp/" + status, { params });
  }
  /**
   * Get the jobs in observable from endpoint
   */
  getProjects(page, pageSize, sort, sortType, tag) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    params = params.append("tag", tag);

    return this.http.get(this.serviceUrl, { params });
  }

  getAllProjectsList(){
    let params = new HttpParams();
    return this.http.get(this.serviceUrl + "/projects-list", { params });
  }
  getProjectSearch(page, pageSize, keyword: string, projectEnable: boolean, sort, sortType, tag) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("keyword", keyword);
    params = params.append("projectEnable", projectEnable);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    params = params.append("tag", tag);
    return this.http.get(this.projectSearch, { params });
  }
  getAllProjectsDashboard(page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/all", { params });
  }
  getAllProjectsDashboardV2(page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/all", { params });
  }
  getAllProjectNames() {
    return this.http.get(this.serviceUrl + "/all-project-names");
  }
  getById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id);
  }
  getOpenApiSpecRaw(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/get-spec");
  }
  getNonOASSpec(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/get-nonOAS-spec");
  }

  //  Getting Spec Analysis Data
  getSpecAnalysisData(id: string, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/" + id + "/spec-analysis", { params });
  }

  create(project: Project) {
    return this.http.post(this.serviceUrl, project);
  }
  test(project: Project) {
    return this.http.post(this.gatewayURL + "/test", project);
  }
  /**
   * API Gateway Discovery Multi-Project Registration Service
   */
  createAll(projectList) {
    return this.http.post(this.serviceUrl + "/addall", projectList);
  }

  update(project: Project) {
    return this.http.put(this.serviceUrl + "/" + project["id"], project);
  }

  delete(project: Project) {
    return this.http.delete(this.serviceUrl + "/" + project["id"]);
  }
  archive(project: Project) {
    return this.http.put(
      this.serviceUrl + "/" + project["id"] + "/archive",
      project
    );
  }

  getCount() {
    return this.http.get(this.serviceUrl + "/count");
  }


  getEndpointsCoverage(projectId, envId, status) {
    let params = new HttpParams();
    params = params.append("envId", envId);

    return this.http.get(this.serviceUrl + "/" + projectId + "/dashboard/" + status, { params });
  }

  getEndpointsCoverageV2(projectId, envId, status) {
    let params = new HttpParams();
    params = params.append("envId", envId);

    return this.http.get(this.serviceUrl + "/" + projectId + "/dashboardV2/" + status, { params });
  }
  getFiles(id: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/" + id + "/files", { params });
  }



  projectSync(projectsync: ProjectSync) {
    return this.http.post(
      this.serviceUrl + "/" + projectsync["projectId"] + "/project-sync",
      projectsync
    );
  }

  syncVulnerabilitiesWithNewIT(projectId) {
    return this.http.get(
      this.serviceUrl + "/" + projectId + "/sync-all",
    );
  }

  //resyn data
  getSyncData(projectId, categry) {
    return this.http.get(
      this.serviceUrl + "/" + projectId + "/GenType/" + categry + "/Sync",
    );
  }




  getAutoCodeConfig(projectId: string) {
    return this.http.get(this.serviceUrl + "/" + projectId + "/autocodeconfig");
  }

  saveEnv(env: Env, projectId: string) {
    return this.http.post(this.envServiceUrl, env);
  }
  updateEnv(env: Env, projectId: string) {
    return this.http.put(
      this.serviceUrl + "/" + projectId + "/env/" + env.id,
      env
    );
  }
  saveEnvs(envs: Env[], projectId: string) {
    return this.http.post(this.serviceUrl + "/" + projectId + "/envs", envs);
  }

  getEnvsByProjectId(projectId: string) {
    return this.http.get(this.envServiceUrl + "/projects/" + projectId);
  }

  getEnvsByProjectIdPageSupported(projectId: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.envServiceUrl + "/projects/" + projectId, {
      params
    });
  }

  deleteEnv(env: Env) {
    return this.http.delete(this.envServiceUrl + "/" + env.id);
  }

  getEnvById(envid: string) {
    return this.http.get(this.envServiceUrl + "/" + envid);
  }

  validateAuths(env: Env) {
    return this.http.post(this.envServiceUrl + "/validate/auths", env);
  }
  cloneEnv(projectId, envId) {
    return this.http.post(this.serviceUrl + "/" + projectId + "/env/" + envId, null);
  }
  searchProject(keyword: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("keyword", keyword);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/search", { params });
  }

  getAutoSuggestions(id: string, toggleValue: string, envId, severity, page, pageSize) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    params = params.append("severity", severity);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(
      this.serviceUrl + "/" + id + "/auto-suggestions/" + toggleValue,
      { params }
    );
  }
  getAutoSuggestionsBackendSort(id: string, toggleValue: string, envId, severity, page, pageSize, sort, sortType, keyword) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    params = params.append("severity", severity);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    // params = params.append("keyword", keyword);
    return this.http.get(
      this.serviceUrl + "/" + id + "/auto-suggestions/" + toggleValue,
      { params }
    );
  }
  getAutoSuggestionsForDownload(id: string, toggleValue: string, envId, severity, category) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    params = params.append("severity", severity);
    params = params.append("category", category);
    // params = params.append("page", page);
    // params = params.append("pageSize", pageSize);
    return this.http.get(
      this.serviceUrl + "/" + id + "/auto-suggestions-download/" + toggleValue,
      { params }
    );
  }

  getAutoSuggestionsForAllDownload(orgId: string, page, pageSize, sort, sortType, category, severity) {
    let params = new HttpParams();
    params = params.append("fetchAll", false);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    params = params.append("category", category);
    params = params.append("severity", severity);


    return this.http.get(
      this.serviceUrl + "/" + orgId + "/org-auto-suggestions-download", { params });
  }

  getOrgVulnerabilities(page, pageSize, sort, sortType, severity, category:string,keyword: string) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    params = params.append("severity", severity);
    params = params.append("category", category);
    params = params.append("keyword", keyword);

    return this.http.get(
      this.serviceUrl + "/org/auto-suggestions", { params });
  }


  skipAutoSuggestion(
    jobId: string,
    suiteName: string,
    tcNumber: string,
    rbacAction: string
  ) {
    let params = new HttpParams();
    params = params.append("rbacAction", rbacAction);
    return this.http.get(
      this.serviceUrl +
      "/" +
      jobId +
      "/auto-suggestions/skip/" +
      suiteName +
      "/" +
      tcNumber,
      { params }
    );
  }

  activateAutoSuggestion(jobId: string, suiteName: string, tcNumber: string) {
    return this.http.get(
      this.serviceUrl +
      "/" +
      jobId +
      "/auto-suggestions/activate/" +
      suiteName +
      "/" +
      tcNumber
    );
  }

  searchAutoSuggestions(
    id: string,
    category,
    status: string,
    keyword,
    page,
    pageSize,
    envId,
    selectedSeverity,
    sort,
    sortType
  ) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    params = params.append("category", category);
    params = params.append("severity", selectedSeverity);
    params = params.append("keyword", keyword);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(
      this.serviceUrl + "/" + id + "/search-auto-suggestions/search/" + status,
      { params }
    );
  }

  updateAutoSuggestion(id: string, autoSuggestion: any) {
    return this.http.post(
      this.serviceUrl + "/" + id + "/update-auto-suggestion",
      autoSuggestion
    );
  }
  reviewAutoSuggestion(id: string, autoSuggestion: any) {
    return this.http.post(
      this.serviceUrl + "/" + id + "/review-auto-suggestion",
      autoSuggestion
    );
  }
  getArchivedRecommendations(id: string, name: string, status: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(
      this.serviceUrl + "/" + id + "/" + name + "/" + status, { params }
    );
  }

  getRecommendationCategoriesCount(id: string, toggleValue: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(
      this.serviceUrl +
      "/" +
      id +
      "/auto-suggestions/category-counts/" +
      toggleValue, { params }
    );
  }

  getEndpoints(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/endpoints");
  }

  getResourceList(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/resource-definitions");
  }



  //close all vul
  clearAllSuggestions(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/close-all-suggestions");
  }

  //archive all vul
  archiveAllSuggestions(id: string, status: string) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/archive-all-suggestions/" + status
    );
  }

  closeSuggestion(id: string, suggestionId: string) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/close-suggestion/" + suggestionId
    );
  }

  deactivateAllSuggestions(id: string) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/deactivate-all-suggestions"
    );
  }


  loadURL(url) {
    return this.http.get(url);
  }

  getGeneratorConf(projectId: string, generatorType: string) {
    return this.http.get(
      this.projectConf + "/" + projectId + "/generator/" + generatorType
    );
  }

  saveProjectConf(projectConf: ProjectConf) {
    return this.http.post(this.projectConf, projectConf);
  }

  refreshSpecs(project: Project) {
    return this.http.put(
      this.serviceUrl + "/" + project["id"] + "/refresh-specs",
      project
    );
  }

  getTestSuiteResponseByIssueId(id: string, issueId: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/get-logs/" + issueId);
  }

  getTestSuiteResponseByRunIdPlaybook(runId, playbookName) {
    return this.http.get(this.serviceUrl + "/get-logs/" + runId + "/" + playbookName);
  }

  saveOas(obj: Project) {
    return this.http.put(this.serviceUrl + "/oas-editor", obj);

  }

  getOpenEndpoint(id, testSuiteName, comment) {
    let action = "open";
    let params = new HttpParams();
    params = params.append("comment", comment);
    return this.http.get(
      this.serviceUrl +
      "/auto-suggestions/" +
      id +
      "/unsecured/" +
      action +
      "/" +
      testSuiteName, { params }
    );
  }

  markTSCompliant(id, testSuiteName) {
    let action = "compliant";
    return this.http.get(
      this.serviceUrl +
      "/auto-suggestions/" +
      id +
      "/compliance/" +
      action +
      "/" +
      testSuiteName
    );
  }

  getEndpointResource(projectId) {
    return this.http.get(
      this.serviceUrl + "/" + projectId + "/endpoint-resource/"
    );
  }

  compareSpec(projectId) {
    return this.http.get(this.serviceUrl + "/" + projectId + "/compare-specs/");
  }



  getVulGraphSnap(id: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(this.serviceUrl + "/" + id + "/vulgraph-snapshot", { params });
  }
  getVulGraphSnapV2(id: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(this.serviceUrl + "/" + id + "/vulgraph-snapshot-V2", { params });
  }
  getBountyValue(id: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(this.serviceUrl + "/" + id + "/bounty-value", { params });
  }

  getEndpointsGraph(id: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/" + id + "/specs-version-graph");
  }

  getReport(id, envId) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(this.reportURL + "/" + id, { params });
  }

  getDetailReportByProjectRun(projectId, runId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(
      //this.reportURL + "/project/" + projectId + "/run/" + runId + "/detail/" + fileType
      this.reportURL + "/project/" + projectId + "/run/" + runId + "/detail", { params }
    );
  }

  getSummaryReportByProjectRun(projectId, runId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(
      this.reportURL + "/project/" + projectId + "/run/" + runId + "/summary", { params }
    );
  }

  getSummaryReport(projectId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(
      this.reportURL + "/project/" + projectId + "/summary", { params }
    );
  }

  getDetailReport(projectId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(
      this.reportURL + "/project/" + projectId + "/detail", { params }
    );
  }

  saveReportSetting(reportSetting: ReportSetting) {
    return this.http.post(this.reportSettingURL, reportSetting);
  }

  getReportSetting(projectId) {
    return this.http.get(this.reportSettingURL + "/project/" + projectId);
  }

  getPentestReport(projectId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(this.reportURL + "/project/" + projectId + "/pentest", { params });
  }

  getDeveloperReport(projectId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(this.reportURL + "/project/" + projectId + "/developer", { params });
  }
  getComplianceReport(projectId, fileType) {
    let params = new HttpParams();
    params = params.append("fileType", fileType);
    return this.http.get(this.reportURL + "/project/" + projectId + "/compliance", { params });
  }

  getVulDetails(id, autoSuggestionId) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/auto-suggestion/" + autoSuggestionId
    );
  }
  getVulCategory(id, autoSuggestionId) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/auto-suggestion-category/" + autoSuggestionId
    );
  }
  getTotalEndpoints(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/count-endpoints");
  }

  reOpenSuggestion(id: string, suggestionId: string) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/reopen-suggestion/" + suggestionId
    );
  }
  //archive
  archiveSuggestion(id: string, suggestionId: string) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/archive-suggestion/" + suggestionId
    );
  }

  //archive by categories
  ArchivebyCategories(id: string, status: string, archiveCategories, envId) {
    let params = new HttpParams();
    params = params.append("envId", envId);

    return this.http.put(
      this.serviceUrl + "/" + id + "/archive-suggestions-by-category/" + status, archiveCategories, { params }
    );
  }

  //close by categories
  ClosebyCategories(id: string, status: string, closeCategories, envId) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.put(
      this.serviceUrl + "/" + id + "/close-suggestions-by-category/" + status, closeCategories, { params }
    );
  }

  invalidTestCounts(id: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);

    return this.http.get(
      this.serviceUrl + "/" + id + "/false-positive-negative-count", { params }
    );
  }



  getVulnerabilityBySeverity(id: string, envId: string) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(this.serviceUrl + "/" + id + "/vulnerability-by-severity", { params });
  }

  getSubscriptionLicense(orgId: string) {
    let params = new HttpParams();
    params = params.append("orgId", orgId);
    return this.http.get(this.serviceUrl + "/" + "orgs/" + orgId + "/subscriptionLicense");
  }

  upgradeLicense(project: Project) {
    return this.http.put(this.serviceUrl + "/" + project["id"] + "/upgradeLicense", project);
  }

  sendNightlyProjectMail(projectId: string) {
    return this.http.get(this.reportURL + "/project/" + projectId + "/nightly");
  }

  getProjectStats(projectId: string) {
    return this.http.get(this.serviceUrl + "/" + projectId + "/getstats");
  }
  getMyProjects(page, pageSize, sort, sortType, tag) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    params = params.append("tag", tag);
    return this.http.get(this.serviceUrl + "/myProjects", { params });
  }

  getVulnerabilityByEndpoints(projectId: string, envId) {
    let params = new HttpParams();
    params = params.append("envId", envId);
    return this.http.get(this.reportURL + "/" + projectId + "/vulnerability-by-Endpoint", { params });
  }

  getAutopilot(projectId: string) {
    let params = new HttpParams();
    // params = params.append("envId", envId);
    return this.http.get(this.serviceUrl + "/" + projectId + "/autopilot/latest", { params });
  }

  getCateogoryByorgId() {
    return this.http.get(this.serviceUrl + "/categorydisplaylabel");
  }
  getReportsEmailList(){
    return this.http.get(this.reportURL +"/" +'org-monthly-report');
  }
  saveAll(emailList){
    return this.http.put(this.reportURL +"/" +'save/org-monthly-report',{emailList});
  }
}